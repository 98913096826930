import React from 'react'

const Spinner = () => {
    return (
        <div class='spinner'>
            
        </div>

    )
}
export default Spinner
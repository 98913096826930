import React, { useState, useEffect, useCallback } from "react";
import NavbarOpen from "./commonComponents/NavbarOpen";
import { Link } from "react-router-dom";
const Navbar = ({ navColor, setNavColor, gotoSection, contact }) => {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  return (
    <>
      <nav className={hamburgerOpen && "nav-transition-border"}>
        <div className="nav-container">
          <div>
            <Link to="/">
              <svg
                width="175"
                height="23"
                viewBox="0 0 175 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.8197 12.0073V3.42462H22.7847V22.7463H20.8197V13.5999V12.0073ZM10.0848 13.5999V22.7463H8.09094V3.42462H10.0848V12.0073V13.5999V13.5999Z"
                  fill={navColor}
                />
                <path
                  d="M23.0592 23H20.5597V3.17096H23.0592V23ZM21.0798 22.4786H22.5101V3.69241H21.0798V22.4786ZM10.3593 23H7.83087V3.17096H10.3593V23Z"
                  fill={navColor}
                />
                <path
                  d="M34.6899 4.98896V12.2751H42.4196V13.7831H34.6899V21.1538H43.2576V22.7463H32.696V3.42462H43.2576V4.98896H34.6899Z"
                  fill={navColor}
                />
                <path
                  d="M43.5321 23H32.436V3.17096H43.5321V5.25674H34.9644V12.0214H42.6941V14.0509H34.9644V20.9001H43.5321V23Z"
                  fill={navColor}
                />
                <path
                  d="M63.7306 18.0392H54.715L52.9234 22.7463H50.814L58.1103 3.86151H60.3353L67.6316 22.7463H65.4932L63.7306 18.0392ZM63.1526 16.5031L59.2372 6.08823L55.2784 16.5031H63.1526Z"
                  fill={navColor}
                />
                <path
                  d="M68.0361 23H65.3199L63.5572 18.2929H54.9028L53.1112 23H50.4239L57.9369 3.60785H60.5231L68.0361 23ZM55.6685 16.2353H62.7626L59.2372 6.84927L55.6685 16.2353Z"
                  fill={navColor}
                />
                <path
                  d="M76.6905 3.42462L83.0621 20.4914L89.4916 3.42462H91.6588L84.3047 22.7463H81.863L74.5233 3.42462H76.6905Z"
                  fill={navColor}
                />
                <path
                  d="M84.4781 23H81.6607L74.1332 3.17096H76.8639L83.0477 19.7445L89.2893 3.17096H92.02L84.4781 23Z"
                  fill={navColor}
                />
                <path
                  d="M102.018 4.98896V12.2751H109.748V13.7831H102.018V21.1538H110.586V22.7463H100.024V3.42462H110.586V4.98896H102.018Z"
                  fill={navColor}
                />
                <path
                  d="M110.86 23H99.7642V3.17096H110.86V5.25674H102.293V12.0214H110.022V14.0509H102.293V20.9001H110.86V23V23Z"
                  fill={navColor}
                />
                <path
                  d="M134.469 22.7463H132.475L121.639 6.60965V22.7463H119.63V3.45279H121.624L132.46 19.6176V3.45279H134.454V22.7463H134.469Z"
                  fill={navColor}
                />
                <path
                  d="M134.729 23H132.316L121.899 7.48344V23H119.37V3.19913H121.783L132.2 18.7439V3.19913H134.729V23Z"
                  fill={navColor}
                />
                <path
                  d="M146.345 21.2102H153.54V22.7463H144.351V3.42462H146.345V21.2102Z"
                  fill={navColor}
                />
                <path
                  d="M153.8 23H144.077V3.17096H146.605V20.9565H153.8V23Z"
                  fill={navColor}
                />
                <path
                  d="M168.768 15.2065V22.7463H166.774V15.2065L160.374 3.42462H162.642L167.757 13.1489L168.768 15.2065Z"
                  fill={navColor}
                />
                <path
                  d="M169.028 23H166.5V15.2629L159.912 3.15686H162.801L167.988 13.0221L169.028 15.136V23Z"
                  fill={navColor}
                />
                <path
                  d="M4.031 9.37192C4.68117 9.97792 5.49026 10.5276 6.44384 10.9926L6.51608 11.0349C7.42631 11.4859 7.52745 11.5282 9.30456 11.8523C11.125 12.1765 11.8908 12.2892 12.8444 12.4301L13.3789 12.5006L20.6897 13.6703L20.6752 14.079L13.1911 12.9516C12.5265 12.8529 11.8908 12.7684 11.2695 12.6979C10.2726 12.5711 9.33346 12.4583 8.49547 12.2892C7.1518 12.0355 5.9526 11.7396 4.94124 11.4154C3.92987 11.0913 3.06298 10.7249 2.34058 10.3162"
                  fill={navColor}
                />
                <path
                  d="M20.6463 14.6146L13.1044 13.473C12.4398 13.3744 11.8041 13.2898 11.1973 13.2194C10.2004 13.0925 9.24679 12.9798 8.39435 12.8107C7.02178 12.5429 5.80814 12.2469 4.76788 11.9087C3.72761 11.5705 2.81738 11.1899 2.06608 10.7671C1.30033 10.3303 0.664613 9.79472 0.173377 9.18872L0 8.97732L3.16414 6.80698L3.26527 7.1734C3.43865 7.82168 3.82875 8.42769 4.42112 8.99141C5.01349 9.55514 5.77924 10.0766 6.68947 10.5276L6.76171 10.5698C7.61415 10.9926 7.67194 11.0208 9.42017 11.345C11.2262 11.6832 11.9341 11.7819 12.9311 11.9228L13.4512 11.9792L20.6174 13.1207L20.8053 13.1912L20.6463 14.6146Z"
                  fill={navColor}
                />
                <path
                  d="M167.757 16.2353L170.56 10.9363C171.08 9.97794 171.528 9.0337 171.875 8.07537C172.236 7.11703 172.481 6.21507 172.64 5.35539C172.799 4.49571 172.828 3.70649 172.727 2.98774C172.626 2.26899 172.38 1.66299 171.99 1.18382L174.576 0.352325C174.75 0.972424 174.793 1.62071 174.706 2.32536C174.62 3.03002 174.432 3.80514 174.143 4.66483C173.854 5.52451 173.45 6.46875 172.944 7.51164C172.438 8.55453 171.73 9.69608 171.008 10.9786L167.771 16.9259"
                  fill={navColor}
                />
                <path
                  d="M171.528 7.76532C171.875 6.82108 172.221 6.13052 172.366 5.28493C172.51 4.45343 172.539 3.67831 172.453 2.98775C172.366 2.31127 172.135 1.76164 171.774 1.31066L171.528 1.01471L174.75 0L174.822 0.26777C174.995 0.916054 175.039 1.60662 174.967 2.33946C174.88 3.05821 174.692 3.86152 174.389 4.73529C174.1 5.59498 173.681 6.5674 173.175 7.62439L171.528 7.76532Z"
                  fill={navColor}
                />
                <path
                  d="M173.088 7.20159C172.265 9.10416 171.412 11.0067 170.444 12.867C170.112 13.5153 169.751 14.1495 169.404 14.7978C168.841 15.8125 168.523 16.7285 168.277 17.8701C167.858 17.8701 167.439 17.8701 167.006 17.8701C166.847 17.2641 166.977 16.6017 167.237 16.038C167.497 15.4602 167.887 14.9528 168.248 14.4314C169.968 11.9651 171.196 9.20281 172.409 6.45465"
                  fill={navColor}
                />
                <path
                  d="M168.494 18.1238L166.803 18.1097L166.746 17.9124C166.543 17.1514 166.76 16.4044 166.991 15.8971C167.222 15.3897 167.54 14.9387 167.858 14.5018L168.031 14.2623C169.751 11.796 170.979 9.01961 172.178 6.32782L172.669 6.53922C171.47 9.25919 170.228 12.0637 168.479 14.5582L168.306 14.7978C167.988 15.2347 167.699 15.6575 167.482 16.1226C167.309 16.5031 167.136 17.0386 167.222 17.5882H168.06C168.32 16.4608 168.653 15.587 169.158 14.6569C169.534 13.9804 169.866 13.3603 170.199 12.7402C171.181 10.8658 172.048 8.92096 172.828 7.10294L173.319 7.30025C172.525 9.13235 171.658 11.0772 170.661 12.9798C170.329 13.614 169.996 14.2341 169.621 14.9105C169.101 15.8407 168.783 16.7426 168.523 17.9124L168.494 18.1238Z"
                  fill={navColor}
                />
              </svg>
            </Link>
          </div>
          <div
            className={
              hamburgerOpen ? "hamburger-nav hamburger-open" : "hamburger-nav"
            }
            onClick={() => setHamburgerOpen(!hamburgerOpen)}
          >
            <div
              className="hamburger-bar"
              style={{ background: navColor }}
            ></div>
            <div
              className="hamburger-bar"
              style={{ background: navColor }}
            ></div>
            <div
              className="hamburger-bar"
              style={{ background: navColor }}
            ></div>
          </div>
        </div>
      </nav>
      <NavbarOpen
        navOpen={hamburgerOpen}
        setNavOpen={setHamburgerOpen}
        gotoSection={gotoSection}
        contact={contact}
      />
    </>
  );
};

export default Navbar;
